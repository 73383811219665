<template>
  <app-layout class="workers container col-lg-9 col-sm-12">
    <template v-slot:header>
      <BaseListToolbar
        title="Workers"
        @search="updateSearchValue"
        @sort="onSort"
        :sortingData="sortingData"
      >
        <template v-slot:inline-filters>
          <WorkerListFilter
            :isLoading="isLoading"
            :availableFilters="availableFilters"
            :outerSelectedFilters="workerFilters"
            @filter="updateFilters"
          />
        </template>
      </BaseListToolbar>
    </template>
    <template v-slot:body>
      <CreateButtonMobile
        v-if="canCreateWorker"
        @click.native="handleAddNewWorker"
      />
      <div v-if="isLoading">
        <v-skeleton-loader
          data-test="loader"
          v-for="i in 10"
          :key="i"
          type="list-item-avatar"
        />
      </div>
      <div class="mb-6" v-else>
        <div v-if="isNotEmpty">
          <v-list-item
            v-for="worker in items"
            :key="worker.id"
            @click="() => handleView(worker)"
          >
            <WorkerListItem data-test="worker" :candidate="worker" />
          </v-list-item>
          <v-pagination
            :total-visible="page.pageSize"
            v-if="showPagination"
            v-model="page.currentPage"
            @input="loadTable"
            :length="page.lastPage"
          />
        </div>
      </div>
      <BaseFooter v-if="canCreateWorker">
        <ExpandedButtons>
          <NewWorkerAction @created="loadTable" />
          <ImportWorkersAction @success="loadTable" />
        </ExpandedButtons>
      </BaseFooter>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";
import BaseFooter from "@/components/common/BaseFooter";
import NewWorkerAction from "@/views/workers/NewWorker/actions/NewWorkerAction";
import ImportWorkersAction from "@/views/workers/NewWorker/actions/ImportWorkersAction";
import { map, size } from "lodash";
import { GET_ALL_WORKERS } from "@/store/modules/workers/action-types";
import {
  CLEAR_INTERMEDIARY_COMPANY,
  CLEAR_CURRENT_WORKER,
  SET_WORKER,
  SET_WORKER_FILTERS
} from "@/store/modules/workers/mutation-types";
import { mapState, mapActions, mapMutations } from "vuex";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";
import WorkerListFilter from "@/views/workers/components/WorkerListFilter";
import WorkerListItem from "@/views/workers/components/WorkerListItem";
import { getFullName } from "@/utils/users";
import generateId from "uuid/v4";
import { getColorByStatus } from "@/utils/workers";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { getSortingParam } from "@/utils/helpers";
import { isPermissioned } from "@/utils/permissions";
import ExpandedButtons from "@/components/common/ExpandedButtons";

export default {
  mixins: [paginationMixin],
  components: {
    CreateButtonMobile,
    appLayout,
    WorkerListFilter,
    BaseListToolbar,
    WorkerListItem,
    BaseFooter,
    NewWorkerAction,
    ImportWorkersAction,
    ExpandedButtons
  },
  created() {
    this.loadTable();
  },
  computed: {
    ...mapState("workers", {
      workers: state => state.workers,
      isLoading: state => state.isFetchingData,
      fetchingError: state => state.fetchingError,
      currentWorkerId: state => state.currentWorkerId,
      workerFilters: state => state.workerFilters
    }),
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    canCreateWorker() {
      return this.permissions["workers.create"];
    },
    canViewWorker() {
      return this.permissions["workers.view"];
    },
    items() {
      return map(this.workers, worker => {
        const { organisation = {} } = worker;
        const fullName = getFullName(worker);

        const agency = organisation ? organisation.name : "None";
        return { ...worker, fullName, agency };
      });
    },
    error() {
      return this.fetchingError;
    },
    isErrorVisible() {
      return !!this.error;
    },
    filterCount() {
      return Object.keys(this.workerFilters).length;
    },
    requestParams() {
      return {
        filter: {
          ...this.workerFilters,
          ...(size(this.search.trim()) && { search: this.search })
        },
        ...this.requestPageParams,
        ...(this.sort && { sort: this.sort })
      };
    }
  },
  data: () => ({
    filtersId: generateId(),
    snackbar: {
      model: false
    },
    currentWorker: {},
    hasErrors: true,
    availableFilters: {},
    search: "",
    sort: "",
    sortingData: null
  }),
  methods: {
    ...mapActions("workers", {
      fetchWorkers: GET_ALL_WORKERS
    }),
    ...mapMutations("workers", {
      clearIntermediaryCompany: CLEAR_INTERMEDIARY_COMPANY,
      clearCurrentWorker: CLEAR_CURRENT_WORKER,
      setWorker: SET_WORKER,
      setWorkerFilters: SET_WORKER_FILTERS
    }),
    isPermissioned,
    async loadTable() {
      const { meta } = await this.fetchWorkers(this.requestParams);
      this.updatePageDetails(meta);
      this.availableFilters = meta.filters;
    },
    handleAddNewWorker() {
      this.clearCurrentWorker();
      this.clearIntermediaryCompany();
      this.resetWorkerData();
      this.workerDialog.dialog = true;
    },
    isNotEmpty() {
      return size(this.items);
    },
    handleView({ id }) {
      if (this.canViewWorker) {
        this.$router.push({
          name: "workerInfo",
          params: { id }
        });
      }
    },
    updateFilters(filters) {
      this.setWorkerFilters(filters);
      this.resetPagination();
      this.loadTable();
    },
    clearFilters() {
      this.setWorkerFilters({});
      this.loadTable();
    },
    getColorByStatus,
    updateSearchValue(text) {
      this.search = text;
      this.resetPagination();
      this.loadTable();
    },
    onSort(items, arr, sortDescArray) {
      const sortingParam = getSortingParam(arr, sortDescArray);
      if (this.sort !== sortingParam && sortingParam) {
        this.resetPagination();
        this.sort = sortingParam;
        if (typeof this.loadTable === "function") {
          this.loadTable();
        }
      }
      return this.items;
    }
  }
};
</script>
